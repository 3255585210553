import React from "react";
import { Row, Col, Button, Card, Image } from "react-bootstrap";

import IconCardUm from "../../../assets/cardUm.png";
import IconCardDois from "../../../assets/cardDois.png";
import IconCardTres from "../../../assets/cardTres.png";
import { useEffect } from "react";

export default () => {
  return (
    <>
      {/* MÓDULOS */}
      <Row id="part-modulos" className="py-5 fGrey">
        <Col md={12} className="my-3">
          <h2 className="fw700 text-center">Conheça nossos módulos</h2>
          <p className="w-75 mx-auto fw300 font18">
            O transporte de cargas é uma atividade que envolve desafios para
            todos os envolvidos na sua realização, e especialmente para as
            empresas pela segurança da mercadoria transportada até o seu destino
            final. 
            Com base nestas dificuldades que surgiu a Flex, um sistema on-line
            com objetivo de entregar mais segurança e agilidade para a cadeia
            lógistico sem impactar na operação.
          </p>
        </Col>
        <Col>
          <Row
            id="card-modulos"
            className="my-3 text-white mx-5 justify-content-center align-items-stretch "
          >
            <Card
              as={Col}
              lg="3"
              style={{ backgroundColor: "#02B4D0" }}
              className="mb-5 mb-lg-0 col-12 p-5 Card"
            >
              <Image src={IconCardUm} alt="" />
              <h6 className="fw700 ">Histórico de viagem</h6>
              <p className="fw300 d-md-flex d-none">Módulo Histórico de Viagem: neste módulo, é possível ter a confirmação de referencia comercial do serviço prestado por pessoa física ou jurídica, tudo isso em qualquer dia/horário tornando o processo mais ágil e sem burocracias.</p>
              <p className="fw300 d-md-none d-flex">Módulo Histórico de Viagem: neste módulo, é possível ter a confirmação de referencia comercial...</p>

            
              <a
                style={{ bottom: "20px", position: "absolute" }}
                href="/HistoricoViagensPage"
              >
                Mais detalhes
              </a>
            </Card>
            <Card
              as={Col}
              lg="3"
              style={{ backgroundColor: "#592CD2" }}
              className="mb-5 mb-lg-0 col-12 mx-3 p-5 Card"
            >
              <Image src={IconCardDois} alt="" />
              <h6 className="fw700 ">Biometria Facial</h6>
              <p className="fw300 d-md-flex d-none">Contamos com inteligência artificial desenvolvida pela própria Flex para comprovação de identidade realizando a prova de vida de forma descomplicada e dentro das normas da Lei Geral de Proteção de Dados - LGPD, combatendo fraudes e trazendo mais segurança na contratação.</p>
              <p className="fw300 d-md-none d-flex">Contamos com inteligência artificial desenvolvida pela própria Flex para comprovação...</p>

              <a
                style={{ bottom: "20px", position: "absolute" }}
                href="/BiometriaFacial"
              >
                Mais detalhes
              </a>
            </Card>
            <Card
              as={Col}
              lg="3"
              style={{ backgroundColor: "#FF8134" }}
              className="mb-5 mb-lg-0 col-12  p-5 Card"
            >
              <Image src={IconCardTres} alt="" />
              <h6 className="fw700 ">Validação de CNH</h6>
              <p className="fw300 d-md-flex d-none">Módulo Validação de Imagem de CNH: Comprovação da autenticidade da CNH apresentada no momento da operação, de forma rápida e eficaz, evitando fraudes e garantindo a legitimidade do documento.</p>
              <p className="fw300 d-md-none d-flex">Módulo Validação de Imagem de CNH: Comprovação da autenticidade da CNH apresentada...</p>

              <a
                style={{ bottom: "20px", position: "absolute" }}
                href="/ValidacaoBiometria"
              >
                Mais detalhes
              </a>
            </Card>
          </Row>
        </Col>
      </Row>
    </>
  );
};
