import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "../src/Pages/Home/index";
import HistoricoViagensPage from "./Pages/Others/Historico.viagens";
import BiometriaFacial from "./Pages/Others/BiometriaFacial/BiometriaFacial";
import ValidacaoBiometria from "./Pages/Others/ValidacaoCNH/ValidacaoCNH"
import PoliticaPivacidade from "./Pages/Others/PoliticaPrivacidade/PoliticaPivacidade"
import PoliticaQualidade from "./Pages/Others/PoliticaQualidade/PoliticaQualidade" 
export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/HistoricoViagensPage" element={<HistoricoViagensPage />} />
        <Route path="/BiometriaFacial" element={<BiometriaFacial />} />
        
        <Route path="/ValidacaoBiometria" element={<ValidacaoBiometria />} />
        <Route path="/PoliticaPrivacidade" element={<PoliticaPivacidade />} />
        <Route path="/PoliticaQualidade" element={<PoliticaQualidade />} />
        <Route path="/PoliticaQualidade" element={<PoliticaQualidade />} />
        
      </Routes>
    </BrowserRouter>
  );
}
