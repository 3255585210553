import React from "react";
import { Row, Col, Button, Image } from "react-bootstrap";

import ImageMan from "../../../assets/montagemLogoMan.png";
import ImageIso1 from "../../../assets/certificacao.png";
import ImageIso2 from "../../../assets/certificacao_1.png";
import ImageLock from "../../../assets/lock-password-line.png";

export default () => {
  return (
    <>
      {/* DESCRIÇÃO FLEX */}
      <Row className="pt-5" id="part-qsomos">
        <Col lg={6} className="ps-5 my-5">
          <Row className="h-100">
            <Col className="d-flex flex-column justify-content-around fGrey">
              <p className="fw300 font18">
                <h2 className="fw700">O que é a Flex Consulta?</h2>
                Somos a Flex e viemos para inovar o setor logístico do
                agronegócio conectando quem conecta o Brasil de ponta a ponta.
                Nosso compromisso é de desenvolver soluções tecnológicas
                entregando segurança e agilidade na operação, reduzindo riscos e
                gastos desnecessários.
              </p>

              <div className="d-flex align-items-center justify-content-between fw700 my-5">
                <Row className="m-0 p-0">
                  <Col lg={6} className="mb-4 mb-lg-0 col-12 d-flex ">
                    <Image
                      className="me-2"
                      style={{ height: "40px" }}
                      src={ImageLock}
                      alt=""
                    />
                    <h5>
                      Segurança da informação por meio de criptografia de
                      ponta-a-ponta dentro das normas da LGPD.
                    </h5>
                  </Col>

                  <Col lg={5} className={"col-12"}>
                    <h5>+115 empresas aderiram a Flex</h5>
                  </Col>
                </Row>
              </div>

              <div id="cont-certificacoes">
                <h2 className="fw700 mb-3">Certificações</h2>
                <a
                  href="https://certificacaoiso.com.br/iso-9001/"
                  target="_blank"
                >
                  <Image className="mx-2 certificacao" src={ImageIso1} alt="" />
                </a>
                <a
                  href="https://certificacaoiso.com.br/iso-27001/"
                  target="_blank"
                >
                  <Image className="mx-2 certificacao" src={ImageIso2} alt="" />
                </a>
              </div>
            </Col>
          </Row>
        </Col>

        <Col
          md={6}
          className="d-none d-lg-flex flex-column justify-content-center align-items-center my-5"
        >
          <Image id="img-man" src={ImageMan} alt="" />
        </Col>
      </Row>
    </>
  );
};
