import React from "react";
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import "./Style.css";

//img
import ImgNotebook from "../../assets/ImgNotebook.png";
import cardUm from "../../assets/cardUm.png";

// Partials
import NavbarPartial from "../Home/Partials/Navbar";
import FooterPartial from "../Home/Partials/Footer";
// import CookieComponent from "../Shared/Cookie"
// import BtnWhatsapp from "../../Components/Button";



const ModHistoricoViagens = () => {
      return (
            <>

                  <NavbarPartial />
                  {/* <BtnWhatsapp /> */}
                  <Container style={{ backgroundColor: "#FF8134" }} fluid>
                        <Row className="cont-default-sub-pages pages-card mx-0" style={{height:'100vh',paddingTop:'10vh'}}>
                              <Col md={6} lg={4} className="d-flex  align-items-center ">
                                    <Row className="d-flex justify-content-center ">
                                          <Col md={11} className="d-flex justify-content-center">
                                                <Image height={90} src={cardUm} />
                                                <p style={{ color: "#f2f2f2" }}>
                                                      <h2 style={{ color: "#f2f2f2" }}>Histórico de viagens</h2>
                                                      Obtenha referências comerciais dos serviços prestados pelo
                                                      contratado e subcontratado de forma fácil e centralizada,
                                                      através de pesquisa que leva menos de 20 segundos e com
                                                      comprovação das viagens realizadas.{" "}
                                                </p>
                                          </Col>
                                    </Row>
                              </Col>
                              <Col md={6} lg={8}
                                    className="h-100 imageModulo  mContainer d-none d-md-flex flex-column align-items-end justify-content-center p-0 m-0"
                              >
                                    <div
                                          style={{ marginRight: "20px !important" }}
                                          className="blocoHistoricoViagem ml-auto d-flex flex-column align-items-end justify-content-center p-0 m-0"
                                    >
                                          <Image width={"120%"} src={ImgNotebook} />
                                    </div>
                              </Col>
                        </Row>

                        <FooterPartial />
                  </Container>
                  {/* <CookieComponent /> */}

            </>
      );
};

export default ModHistoricoViagens;
