import React from "react";
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import "./Style.css";

// Partials
import NavbarPartial from "../../Home/Partials/Navbar";
import FooterPartial from "../../Home/Partials/Footer";
// import CookieComponent from "../../Shared/Cookie";

//Imagens
import Card3 from "../../../assets/cardTres.png";
import imgManValidaCNH from "../../../assets/portrait-man-face.jpg";
// import BtnWhatsapp from "../../../Components/Button";

const ValidacaoBiometria = () => {
  return (
    <>
      <NavbarPartial />
      {/* <BtnWhatsapp /> */}
      <Container style={{ backgroundColor: "#FF8134" }} fluid>
        <Row className="backgroudCnh " >
          <Col
            className="d-flex justify-content-center align-items-center"
            md={5}
          >
            <Row className="d-flex justify-content-center ">
              <Col md={11} className="d-flex justify-content-center ">
                <Image height={90} src={Card3} />
                <p style={{ color: "#f2f2f2" }}>
                  <h2 style={{ color: "#f2f2f2" }}>
                    Validação de Imagem de CNH
                  </h2>
                  Garanta a legitimidade da CNH através do nosso módulo de
                  validação de imagem de CNH, processo que é realizado em menos
                  de 60 segundos.{" "}
                </p>
              </Col>
            </Row>
          </Col>

          <Col
            md={7}

            className=" mContainer d-none d-md-flex  flex-column align-items-end justify-content-center p-0  m-0"
          >       
            <Image
              src={imgManValidaCNH}
              style={{ borderRadius:' 30px 0px 0px 30px'}}
              className="img-validacao-biometria"
            /> 
          </Col>
        </Row>
        <FooterPartial />

      </Container>
      {/* <CookieComponent /> */}

    </>
  );
};

export default ValidacaoBiometria;
