import React from "react";
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import "./Style.css";

// Partials
import NavbarPartial from "../../Home/Partials/Navbar";
import FooterPartial from "../../Home/Partials/Footer";
// import CookieComponent from "../../Shared/Cookie";
// import BtnWhatsapp from "../../../Components/Button";
//Imagens
import Card2 from "../../../assets/cardDoisWhite.png";
import imgManSelfie from "../../../assets/woman-with-phone.jpg";

const BiometriaFacial = () => {
  return (
    <>
      <NavbarPartial />
      {/* <BtnWhatsapp /> */}
      <Container style={{ backgroundColor: "#FF8134" }} fluid>
        <Row className="backgroudBiometria m-0  p-0" style={{height:'100vh',paddingTop:'15vh'}}>
          <Col md={8} lg={6} className="col-12 d-flex justify-content-center align-items-center mt-3 p-0">
            <Row className="d-flex justify-content-center  p-0 m-0">
              <Col md={12} className="col-biometria d-flex justify-content-center  p-0 mx-0">
                <Image height={90} src={Card2} />
                <p className={'descBiometria'} style={{ color: "#f2f2f2" }}>
                  <h2 style={{ color: "#f2f2f2" }} >Biometria Facial</h2>
                  Na Flex você conta com a tecnologia de inteligência artificial
                  (IA) através de uma solução própria que é capaz de realizar a
                  captura da foto de perfil em alta qualidade para verificação
                  biométrica e prova de vida do usuário, evitando reprovação
                  indevida em razão da qualidade baixa de imagem, o que poderia
                  tornar o processo demorado e ineficiente. Tudo em conformidade
                  com a LGPD e garantida a entrega do resultado em até 5
                  minutos.{" "}
                </p>
              </Col>
            </Row>
          </Col>

          <Col md={4} lg={6} className="col-12 mContainer d-none d-md-flex flex-column align-items-end justify-content-center p-0 m-0">

                <Image src={imgManSelfie} style={{maxHeight:'80%',maxWidth:'80%',borderRadius:' 30px 0px 0px 30px'}}  className=""/>
            
          </Col>
        </Row>
        <FooterPartial />

      </Container>
      {/* <CookieComponent /> */}

    </>
  );
};

export default BiometriaFacial;
