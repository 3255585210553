import React from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import "./Home.css";
// import CookieComponent from "../Shared/Cookie";

// Partials
import NavbarPartial from "./Partials/Navbar";
import InicioPartial from "./Partials/Inicio";
import SobrePartial from "./Partials/Sobre";
import ModulosPartial from "./Partials/Modulos";
import EmpresasPartial from "./Partials/Empresas";
import ContatosPartial from "./Partials/Contatos";
import FooterPartial from "./Partials/Footer";
// import PromocaoPartial from "./Partials/Promocao";
// import BtnWhatsapp from "../../Components/Button";

const Home = () => {
  return (
    <>
      <NavbarPartial />
      {/* <BtnWhatsapp /> */}
      <Container fluid>
        {/* <PromocaoPartial /> */}
        <InicioPartial />
        <SobrePartial />
        <ModulosPartial />
        <EmpresasPartial />
        <ContatosPartial />
        <FooterPartial />
        {/* <CookieComponent /> */}
      </Container>
    </>
  );
};

export default Home;
