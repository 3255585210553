import React from 'react';
import Router from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    
    <Router/>
  
  );
}

export default App;
