import React from "react";
import { Row, Col, Button, Form,Spinner  } from "react-bootstrap";
import InputMaterialUi from "input-material-ui";
import { useState } from "react";
import { validateEmail, phoneMask,onlyNumbers } from "../../../Utils/ValidationsMasks";
import TextField from "@material-ui/core/TextField";
import { useEffect } from "react";
import SweetAlert from "../../../Utils/SweetAlert";
import Api from "../../../Utils/Axios";
import pdfAvisoPrivacidade from '../../../assets/Pdf/Aviso_de_privacidade_Flex_Consulta_V1.2.pdf'

export default () => {
  const [nome, setNome] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [submited, setSubmited] = useState(false);
  const [check,setCheck] = useState(false);
  const [valid,setValid] = useState(true);
  const [loading,setLoading] = useState(false);

  const handleChange = (event) => {};

  const handleChangePhone = (event) => {
    setPhone(phoneMask(event));
  };
  const handleChangeEmail = (event) => {
    setSubmited(false);
    setEmail(event);
  };

  function ClearInputs() {
      setMensagem("");
      setNome("");
      setEmail("");
      setPhone("");
      setEmpresa("");
      setDepartamento("");
      setSubmited(false);
    }

  useEffect(()=>{
      console.log(phone.length)
  },[phone])

  function handleSubmit(){
      setSubmited(true);
      if(nome == "" || !validateEmail(email) || (phone == "" || phone.length < 14 ) || empresa == "" || departamento == "" || mensagem == ""){
            SweetAlert.small({
                  timer: 2000,
                  type: "error",
                  title: "Preencha os campos corretamente!",
                  cb: () => {},
                });
      }else{
            const formData = new FormData();
            formData.append("nome",nome);
            formData.append("email",email);
            formData.append("telefone",phone);            
            formData.append("empresa",empresa);            
            formData.append("departamento",departamento);            
            formData.append("mensagem",mensagem);            
           
            setLoading(true);
            Api.post("/site_oficial/fale_conosco",formData).then(()=>{
              setLoading(false);
              ClearInputs();    
              SweetAlert.small({
                timer: 2000,
                type: "success",
                title: "Enviado com sucesso!",
                cb: () => {},
              });
            }).catch((e)=>{
                  setLoading(false);    

              SweetAlert.small({
                timer: 2000,
                type: "error",
                title: e,
                cb: () => {},
              });
            }) 
      }
  }

  return (
    <>
      {/* CONTATOS */}
      <Row id="part-contact">
        <Form
          id="form-contact"
          as={Col}
          lg={5}
          className={"col-12 ms-lg-auto p-5"}
        >
          <h4 id="faleConosco" className="fw700 ">
            Fale Conosco
          </h4>

          <TextField
            id="standard-multiline-static"
            fullWidth
            label={
              <span>
                Nome<span className="text-danger">*</span>
              </span>
            }
            placeholder="Digite o nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            helperText={
              <span
                style={{
                  color: "rgb(242,189,189)",
                  fontSize: "12px",
                  visibility: submited && nome == "" ? "visible" : "hidden",
                }}
              >
                Informe um nome válido!
              </span>
            }
          />

          <TextField
            id="standard-multiline-static"
            fullWidth
            label={
              <span>
                E-mail<span className="text-danger">*</span>
              </span>
            }
            placeholder="Digite seu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            helperText={
              <span
                style={{
                  color: "rgb(242,189,189)",
                  fontSize: "12px",
                  visibility:
                    submited && !validateEmail(email) ? "visible" : "hidden",
                }}
              >
                Informe um email válido!
              </span>
            }
          />

          <TextField
            id="standard-multiline-static"
            fullWidth
            label={
              <span>
                Telefone<span className="text-danger">*</span>
              </span>
            }
            placeholder="Digite seu telefone"
            value={phone}
            onChange={(e) => setPhone(phoneMask(onlyNumbers(e.target.value)))}
            helperText={
              <span
                style={{
                  color: "rgb(242,189,189)",
                  fontSize: "12px",
                  visibility: submited && (phone == "" || phone.length < 14 ) ? "visible" : "hidden",
                }}
              >
                Informe um telefone válido!
              </span>
            }
          />

          <TextField
            id="standard-multiline-static"
            fullWidth
            label={
              <span>
                Empresa<span className="text-danger">*</span>
              </span>
            }
            placeholder="Digite a empresa"
            value={empresa}
            onChange={(e) => setEmpresa(e.target.value)}
            helperText={
              <span
                style={{
                  color: "rgb(242,189,189)",
                  fontSize: "12px",
                  visibility: submited && empresa == "" ? "visible" : "hidden",
                }}
              >
                Informe uma empresa válida!
              </span>
            }
          />

          <TextField
            id="standard-multiline-static"
            fullWidth
            label={
              <span>
                Departamento<span className="text-danger">*</span>
              </span>
            }
            placeholder="Digite o departamento"
            value={departamento}
            onChange={(e) => setDepartamento(e.target.value)}
            helperText={
              <span
                style={{
                  color: "rgb(242,189,189)",
                  fontSize: "12px",
                  visibility:
                    submited && departamento == "" ? "visible" : "hidden",
                }}
              >
                Informe um departamento válido!
              </span>
            }
          />

          <TextField
            id="standard-multiline-static"
            label={
              <span>
                Mensagem<span className="text-danger">*</span>
              </span>
            }
            fullWidth
            placeholder="Digite a mensagem"
            multiline
            rows={3}
            helperText={
                  <span
                    style={{
                      color: "rgb(242,189,189)",
                      fontSize: "12px",
                      visibility:
                        submited && mensagem == "" ? "visible" : "hidden",
                    }}
                  >
                    Informe uma mensagem válida!
                  </span>
                }
            value={mensagem}
            onChange={(e) => setMensagem(e.target.value)}
          />

          <Form.Group className="mt-3" controlId="formBasicCheckbox">
            <Form.Check
              onChange={()=>setCheck(!check)}
              value = {check}    
              style={{ fontSize: "14px" }}
              type="checkbox"
              label="A Flex utiliza as informações de contato que você nos fornece através do formulário acima, para entrar em contato com relação aos produtos e serviços. Para obter mais informações sobre como tratamos os seus dados pessoais, confira nossa"
            />
            <a target="_blank" href={pdfAvisoPrivacidade} className="ms-4">
                  Aviso de Privacidade
                </a>
           
          </Form.Group>

          <Button
            disabled={!check || loading}
            onClick={() => handleSubmit()}
            className="ms-3 px-5 mt-3 buttonOrange"
          >
            Enviar
            {loading && (<Spinner size="sm" animation="grow"></Spinner>)}
          </Button>
        </Form>
      </Row>
    </>
  );
};
