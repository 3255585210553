import React from "react";
import { Button, Modal, Row, Col, Form, Spinner } from "react-bootstrap";
import InputMaterialUi from "input-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { validateEmail, phoneMask, onlyNumbers } from "../../Utils/ValidationsMasks";
import SweetAlert from "../../Utils/SweetAlert";
import Api from "../../Utils/Axios";
import pdfAvisoPrivacidade from '../../assets/Pdf/Aviso_de_privacidade_Flex_Consulta_V1.2.pdf'

import "./style.css";
import { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ModalAdquiraFlex = (props) => {
  const [cargo, setCargo] = useState("");
  const [nFuncionario, setNFuncionario] = useState("");
  const [segmento, setSegmento] = useState("");
  const [socialMedia, setSocialMedia] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [submited, setSubmited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false)
  const classes = useStyles();

  useEffect(() => {
    if (props.show === false) {
      ClearInputs();
    }
  }, [props.show]);

  function ClearInputs() {
    setCargo("");
    setNFuncionario("");
    setSegmento("");
    setSocialMedia("");
    setMensagem("");
    setNome("");
    setEmail("");
    setTelefone("");
    setEmpresa("");
    setDepartamento("");
    setSubmited(false);
  }


  function handleSubmit() {
    setSubmited(true);

    if (departamento === "" || cargo === "" || nFuncionario === "" || segmento === "" || socialMedia === "" || nome === "" || email === "" || !validateEmail(email) || (telefone == "" || telefone.length < 14) || empresa === "") {
      SweetAlert.small({
        timer: 2000,
        type: "error",
        title: "Preencha os campos corretamente!",
        cb: () => { },
      });
    } else {

      const formData = new FormData();
      formData.append("nome", nome);
      formData.append("email", email);
      formData.append("telefone", telefone);
      formData.append("empresa", empresa);
      formData.append("departamento", departamento);
      formData.append("mensagem", mensagem);
      formData.append("cargo", cargo);
      formData.append("num_funcionarios", nFuncionario);
      formData.append("segmento", segmento);
      formData.append("rede_social", socialMedia);
      setLoading(true);
      Api.post("/site_oficial/adquira_flex", formData).then(() => {
        setLoading(false);
        ClearInputs();
        SweetAlert.small({
          timer: 2000,
          type: "success",
          title: "Enviado com sucesso!",
          cb: () => { },
        });
      }).catch((e) => {
        setLoading(false);
        SweetAlert.small({
          timer: 2000,
          type: "error",
          title: e,
          cb: () => { },
        });
      })
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={() => props.setShow(false)}
      centered
      size="lg"
    >
      <Modal.Body>
        <Form>
          <h2 className="fw700 fGrey px-4">Adquira a Flex</h2>

          <Row >
            {" "}
            <Col className="px-5 col-12 col-lg-6" id="FormAdquira">
              <TextField
                id="standard-multiline-static"
                fullWidth
                label={
                  <span>
                    Nome<span className="text-danger">*</span>
                  </span>
                }
                placeholder="Digite o nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                helperText={
                  <span
                    style={{
                      color: "rgb(242,189,189)",
                      fontSize: "12px",
                      visibility: submited && nome == "" ? "visible" : "hidden",
                    }}
                  >
                    Informe um nome válido!
                  </span>
                }
              />

              <TextField
                id="standard-multiline-static"
                fullWidth
                label={
                  <span>
                    E-mail<span className="text-danger">*</span>
                  </span>
                }
                placeholder="Digite o e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                helperText={
                  <span
                    style={{
                      color: "rgb(242,189,189)",
                      fontSize: "12px",
                      visibility:
                        submited && !validateEmail(email) ? "visible" : "hidden",
                    }}
                  >
                    Informe um email válido!
                  </span>
                }
              />

              <TextField
                id="standard-multiline-static"
                fullWidth
                label={
                  <span>
                    Telefone<span className="text-danger">*</span>
                  </span>
                }
                placeholder="Digite o telefone"
                value={telefone}
                onChange={(e) => setTelefone(phoneMask(onlyNumbers(e.target.value)))}

                helperText={
                  <span
                    style={{
                      color: "rgb(242,189,189)",
                      fontSize: "12px",
                      visibility: submited && (telefone == "" || telefone.length < 14) ? "visible" : "hidden",
                    }}
                  >
                    Informe um telefone válido!
                  </span>
                }
              />
              <TextField
                id="standard-multiline-static"
                fullWidth
                label={
                  <span>
                    Empresa<span className="text-danger">*</span>
                  </span>
                }
                placeholder="Digite o nome da empresa"
                value={empresa}
                onChange={(e) => setEmpresa(e.target.value)}
                helperText={
                  <span
                    style={{
                      color: "rgb(242,189,189)",
                      fontSize: "12px",
                      visibility:
                        submited && empresa == "" ? "visible" : "hidden",
                    }}
                  >
                    Informe uma empresa válida!
                  </span>
                }
              />
              <TextField
                id="standard-multiline-static"
                fullWidth
                label={
                  <span>
                    Departamento<span className="text-danger">*</span>
                  </span>
                }
                placeholder="Digite o departamento"
                value={departamento}
                onChange={(e) => setDepartamento(e.target.value)}
                helperText={
                  <span
                    style={{
                      color: "rgb(242,189,189)",
                      fontSize: "12px",
                      visibility: submited && departamento == "" ? "visible" : "hidden",
                    }}
                  >
                    Informe um departamento válido!
                  </span>
                }
              />
            </Col>
            <Col className="px-5  col-12  col-lg-6" id="FormAdquira">
              <FormControl
                style={{ width: "100%", marginTop: "5px" }}
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-label">
                  <span>
                    Cargo<span className="text-danger">*</span>
                  </span>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={cargo}
                  onChange={(e) => setCargo(e.target.value)}
                >
                  <MenuItem value={""}>Selecione...</MenuItem>
                  <MenuItem value={" Analista"}> Analista</MenuItem>
                  <MenuItem value={"Autônomo(a)"}>Autônomo(a)</MenuItem>
                  <MenuItem value={"Coordenador(a)"}>Coordenador(a)</MenuItem>
                  <MenuItem value={"Diretor(a)"}>Diretor(a)</MenuItem>
                  <MenuItem value={"Estagiário(a)"}>Estagiário(a)</MenuItem>
                  <MenuItem value={"Fundador(a)/CEO"}>Fundador(a)/CEO</MenuItem>
                  <MenuItem value={"Gerente"}>Gerente</MenuItem>
                  <MenuItem value={"Sócio(a)"}>Sócio(a)</MenuItem>
                  <MenuItem value={"Outros"}>Outros</MenuItem>
                </Select>
                <FormHelperText
                  style={{
                    visibility: submited && cargo == "" ? "visible" : "hidden",
                    color: "rgb(242,189,189)",
                  }}
                >
                  Informe um cargo válido!
                </FormHelperText>
              </FormControl>
              <FormControl
                style={{ width: "100%", marginTop: "5px" }}
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-label">
                  <span>
                    Nº Funcionários<span className="text-danger">*</span>
                  </span>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={nFuncionario}
                  onChange={(e) => setNFuncionario(e.target.value)}
                >
                  <MenuItem value={""}>Selecione...</MenuItem>
                  <MenuItem value={"1 - 20"}>1 - 20 colaboradores</MenuItem>
                  <MenuItem value={"21 - 50"}>21 - 50 colaboradores</MenuItem>
                  <MenuItem value={"51 - 150"}>51 - 150 colaboradores</MenuItem>
                  <MenuItem value={"151 - 500"}>151 - 500 colaboradores</MenuItem>
                  <MenuItem value={"501 - 1500"}>501 - 1500 colaboradores</MenuItem>
                  <MenuItem value={"+1500"}>+1500 colaboradores</MenuItem>
                </Select>
                <FormHelperText
                  style={{
                    visibility:
                      submited && nFuncionario == "" ? "visible" : "hidden",
                    color: "rgb(242,189,189)",
                  }}
                >
                  Informe um valor válido
                </FormHelperText>
              </FormControl>
              <FormControl
                style={{ width: "100%", marginTop: "5px" }}
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  <span>
                    Segmento<span className="text-danger">*</span>
                  </span>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={segmento}
                  onChange={(e) => setSegmento(e.target.value)}
                >
                  <MenuItem value={""}>Selecione...</MenuItem>
                  <MenuItem value={"Agenciadora Digital de Cargas"}>
                    Agenciadora Digital de Cargas
                  </MenuItem>
                  <MenuItem value={"Corretora"}>Corretora</MenuItem>
                  <MenuItem value={"Embarcador"}>Embarcador</MenuItem>
                  <MenuItem value={"Gerenciamento de Risco"}>
                    Gerenciamento de Risco
                  </MenuItem>
                  <MenuItem value={"Seguradora"}>Seguradora</MenuItem>

                  <MenuItem value={"Transportadora"}>Transportadora</MenuItem>


                  <MenuItem value={"Outros"}>Outros</MenuItem>
                </Select>
                <FormHelperText
                  style={{
                    visibility:
                      submited && segmento == "" ? "visible" : "hidden",
                    color: "rgb(242,189,189)",
                  }}
                >
                  Informe o segmento válido!
                </FormHelperText>
              </FormControl>

              <FormControl
                style={{ width: "100%", marginTop: "5px" }}
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-label">
                  <span>
                    Onde nos conheceu?<span className="text-danger">*</span>
                  </span>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={socialMedia}
                  label="Standard"
                  onChange={(e) => setSocialMedia(e.target.value)}
                >
                  <MenuItem value={""}>Selecione...</MenuItem>
                  <MenuItem value={"Facebook"}>Facebook</MenuItem>
                  <MenuItem value={"Google"}>Google</MenuItem>
                  <MenuItem value={"Instagram"}>Instagram</MenuItem>
                  <MenuItem value={"Linkedin"}>Linkedin</MenuItem>
                  <MenuItem value={"Outros"}>Outros</MenuItem>
                </Select>
                <FormHelperText
                  style={{
                    visibility:
                      submited && socialMedia == "" ? "visible" : "hidden",
                    color: "rgb(242,189,189)",
                  }}
                >
                  Informe o local válido!
                </FormHelperText>
              </FormControl>
            </Col>
            <Col md={12} className="px-5" id="FormAdquira">
              <TextField
                id="standard-multiline-static"
                label="Mensagem"
                fullWidth
                placeholder="Digite a mensagem"
                multiline
                rows={3}
                value={mensagem}
                onChange={(e) => setMensagem(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="px-5" id="FormAdquira">
              <Form.Group className="mt-3" controlId="formBasicCheckbox">
                <Form.Check
                  onChange={() => setCheck(!check)}
                  value={check}
                  style={{ fontSize: "14px" }}
                  type="checkbox"
                  label="A Flex utiliza as informações de contato que você nos fornece através do formulário acima, para entrar em contato com relação aos produtos e serviços. Para obter mais informações sobre como tratamos os seus dados pessoais, confira nossa"
                />
                <a target="_blank" href={pdfAvisoPrivacidade} className="ms-4">
                  Aviso de Privacidade
                </a>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col>
            <Button
              onClick={() => props.setShow(false)}
              className="mx-3 px-4 buttonWhiteModal"
            >
              Cancelar
            </Button>
            <Button

              disabled={loading || departamento === "" || cargo === "" || nFuncionario === "" || segmento === "" || socialMedia === "" || nome === "" || email === "" || telefone === "" || empresa === "" || !check}
              onClick={() => handleSubmit()}
              className="buttonOrangeModal px-5"
            >
              Enviar
              {loading && (<Spinner size="sm" animation="grow"></Spinner>)}

            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdquiraFlex;
