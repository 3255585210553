import React from "react";
import { Row, Col, Button, Image } from "react-bootstrap";
import Marquee from "react-fast-marquee";

import Icon1 from "../../../assets/empresas/1.png";
import Icon2 from "../../../assets/empresas/2.png";
import Icon3 from "../../../assets/empresas/3.png";
import Icon4 from "../../../assets/empresas/4.png";
import Icon5 from "../../../assets/empresas/5.png";
import Icon6 from "../../../assets/empresas/6.png";
import Icon7 from "../../../assets/empresas/7.png";
import Icon8 from "../../../assets/empresas/8.png";
import Icon9 from "../../../assets/empresas/9.png";
import Icon10 from "../../../assets/empresas/10.png";

export default () => {
      return <>
            {/* EMPRESAS */}
            <Row id="part-empresas">
                  <Col md={12} className=" mt-2 mb-5">
                        <h2 className="text-center fw700 fGrey">
                              Veja quem confia no nosso trabalho
                        </h2>
                  </Col>
                  <Col md={12} id="container-empresas" className="mb-5">
                        <Marquee pauseOnHover gradient={false}>
                              <Image src={Icon1} alt="" />
                              <Image src={Icon2} alt="" />
                              <Image src={Icon3} alt="" />
                              <Image src={Icon4} alt="" />
                              <Image src={Icon5} alt="" />
                              <Image src={Icon6} alt="" />
                              <Image src={Icon7} alt="" />
                              <Image src={Icon8} alt="" />
                              <Image src={Icon9} alt="" />
                              <Image src={Icon10} alt="" />
                        </Marquee>
                  </Col>
            </Row>
      </>
}