import React from "react";
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import FooterPartial from "../../Home/Partials/Footer";
import NavbarPartial from "../../Home/Partials/Navbar";
// import CookieComponent from "../../Shared/Cookie";
// import BtnWhatsapp from "../../../Components/Button";

const PoliticaPivacidade = () => {
  return (
    <>
      <NavbarPartial />
      {/* <BtnWhatsapp /> */}
      <Container fluid>
        <Row className="m-0 p-0">
          <Col
            style={{
              marginTop: "182px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            md={6}
          >
            <h1 style={{ color: "#636363" }}>Politica de Privacidade</h1>
            <p style={{ color: "#636363" }}>
              Este site é mantido e operado pela Flex Consulta (Casimiro &amp;
              Miranda Serviços LTDA). A Flex Consulta é uma empresa brasileira
              com sede em Cuiabá/MT, especializada na análise de riscos
              relacionados ao transporte rodoviário de cargas através de um
              sistema inovador que faz consulta de históricos de viagens
              realizadas por motoristas de caminhões.
            </p>
            <p style={{ color: "#636363" }}>
              Nós coletamos e utilizamos alguns dados pessoais que pertencem
              àqueles que utilizam nosso site. Ao fazê-lo, agimos na qualidade
              de controlador desses dados e estamos sujeitos às disposições da
              Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados
              Pessoais - LGPD). Nós cuidamos da proteção de seus dados pessoais
              e, por isso, disponibilizamos esta política de privacidade, que
              contém informações importantes sobre:
            </p>
            <p style={{ color: "#636363" }}>
              - Quem deve utilizar nosso site
              <br />
              - Quais dados coletamos e o que fazemos com eles;
              <br />
              - Seus direitos em relação aos seus dados pessoais; e <br />
              - Como entrar em contato conosco. <br />
            </p>
            <p style={{ color: "#636363" }}>
              <b>1. Dados que coletamos e motivos da coleta</b>
              <br />
              Nosso site coleta e utiliza alguns dados pessoais de nossos
              usuários, de acordo com o disposto nesta seção.
            </p>
            <p style={{ color: "#636363" }}>
              <b>1.2 Dados pessoais fornecidos expressamente pelo usuário</b>
              <br />
              Nós coletamos os seguintes dados pessoais que nossos usuários nos
              fornecem expressamente ao utilizar nosso site:
            </p>
            <p style={{ color: "#636363" }}>
              - Empresa em que trabalha;
              <br />
              - Nome completo;
              <br />
              - e-mail corporativo.
              <br />
            </p>
            <p style={{ color: "#636363" }}>
              A coleta destes dados ocorre nos seguintes momentos:
            </p>
            <p style={{ color: "#636363" }}>- Cadastro de usuário</p>
            <p style={{ color: "#636363" }}>
              Os dados fornecidos por nossos usuários são coletados com as
              seguintes finalidades:
            </p>
            <p style={{ color: "#636363" }}>
              - Possibilitar o acesso às consultas da flex.
            </p>
            <p style={{ color: "#636363" }}>
              <b>1.3 Dados sensíveis</b>
              <br />
              Não serão coletados dados sensíveis de nossos usuários, assim
              entendidos aqueles definidos nos arts. 11 e seguintes da Lei de
              Proteção de Dados Pessoais. Assim, não haverá coleta de dados
              sobre origem racial ou étnica, convicção religiosa, opinião
              política, filiação a sindicato ou a organização de caráter
              religioso, filosófico ou político, dado referente à saúde ou à
              vida sexual, dado genético ou biométrico, quando vinculado a uma
              pessoa natural.
            </p>
            <p style={{ color: "#636363" }}>
              <b>1.4 Coleta de dados não previstos expressamente</b>
              <br />
              Eventualmente, outros tipos de dados não previstos expressamente
              nesta Política de poderão ser coletados, desde que sejam
              fornecidos com o consentimento do usuário, ou, ainda, que a coleta
              seja permitida com fundamento em outra base legal prevista em lei.
            </p>
            <p style={{ color: "#636363" }}>
              Em qualquer caso, a coleta de dados e as atividades de tratamento
              dela decorrentes serão informadas aos usuários do site.
            </p>

            <p style={{ color: "#636363" }}>
              <b>2. Compartilhamento de dados pessoais com terceiros</b>
              <br />
              Nós não compartilhamos seus dados pessoais com terceiros. Apesar
              disso, é possível que compartilhemos dados com terceiros para
              cumprir alguma determinação legal ou regulatória, ou, ainda, para
              cumprir alguma ordem expedida por autoridade pública.
            </p>
            <p style={{ color: "#636363" }}>
              <b>3. Por quanto tempo seus dados pessoais serão armazenados</b>
              <br />
              Os dados pessoais coletados pelo site são armazenados e utilizados
              por período de tempo que corresponda ao necessário para atingir as
              finalidades elencadas neste documento e que considere os direitos
              de seus titulares, os direitos do controlador do site e as
              disposições legais ou regulatórias aplicáveis.
            </p>
            <p style={{ color: "#636363" }}>
              Uma vez expirados os períodos de armazenamento dos dados pessoais,
              eles são removidos de nossas bases de dados ou anonimizados, salvo
              nos casos em que houver a possibilidade ou a necessidade de
              armazenamento em virtude de disposição legal ou regulatória.
            </p>
            <p style={{ color: "#636363" }}>
              <b>4. Bases legais para o tratamento de dados pessoais </b>
              <br />
              Cada operação de tratamento de dados pessoais precisa ter um
              fundamento jurídico, ou seja, uma base legal, que nada mais é que
              uma justificativa que a autorize, prevista na Lei Geral de
              Proteção de Dados Pessoais.
            </p>
            <p style={{ color: "#636363" }}>
              A nossa atividade de tratamento de dados pessoais no papel de
              agente controlador possui como base legal que fundamenta a
              execução do contrato.
            </p>
            <p style={{ color: "#636363" }}>
              <b>5. Direitos do usuário</b>
              <br />O usuário do site possui os seguintes direitos, conferidos
              pela Lei de Proteção de Dados Pessoais:
            </p>
            <p style={{ color: "#636363" }}>
              - confirmação da existência de tratamento;
              <br />- acesso aos dados;
            </p>

            <p style={{ color: "#636363" }}>
              - correção de dados incompletos, inexatos ou desatualizados;
            </p>
            <p style={{ color: "#636363" }}>
              - anonimização, bloqueio ou eliminação de dados desnecessários,
              excessivos ou tratados em desconformidade com o disposto na lei;
            </p>
            <p style={{ color: "#636363" }}>
              - portabilidade dos dados a outro fornecedor de serviço ou
              produto, mediante requisição expressa, de acordo com a
              regulamentação da autoridade nacional, observados os segredos
              comercial e industrial;
            </p>
            <p style={{ color: "#636363" }}>
              - eliminação dos dados pessoais tratados com o consentimento do
              titular, exceto nos casos previstos em lei;
            </p>

            <p style={{ color: "#636363" }}>
              - informação das entidades públicas e privadas com as quais o
              controlador realizou uso compartilhado de dados;
            </p>
            <p style={{ color: "#636363" }}>
              - informação sobre a possibilidade de não fornecer consentimento e
              sobre as consequências da negativa;
            </p>
            <p style={{ color: "#636363" }}>- revogação do consentimento.</p>

            <p style={{ color: "#636363" }}>
              É importante destacar que, nos termos da LGPD, não existe um
              direito de eliminação de dados tratados com fundamento em bases
              legais distintas do consentimento, a menos que os dados seja
              desnecessários, excessivos ou tratados em desconformidade com o
              previsto na lei. Para garantir que o usuário que pretende exercer
              seus direitos é, de fato, o titular dos dados pessoais objeto da
              requisição, poderemos solicitar documentos ou outras informações
              que possam auxiliar em sua correta identificação, a fim de
              resguardar nossos direitos e os direitos de terceiros. Isto
              somente será feito, porém, se for absolutamente necessário, e o
              requerente receberá todas as informações relacionadas.
            </p>
            <p style={{ color: "#636363" }}>
              <b>6. Medidas de segurança no tratamento de dados pessoais</b>
              <br />
              Empregamos medidas técnicas e organizativas aptas a proteger os
              dados pessoais de acessos não autorizados e de situações de
              destruição, perda, extravio ou alteração desses dados. As medidas
              qu utilizamos levam em consideração a natureza dos dados, o
              contexto e a finalidade do tratamento, os riscos que uma eventual
              violação geraria para os direitos e liberdades do usuário, e os
              padrões atualmente empregados no mercado por empresas semelhantes
              à nossa. Entre as medidas de segurança adotadas por nós,
              destacamos as seguintes:
            </p>
            <p style={{ color: "#636363" }}>
              - Os dados de nossos usuários são armazenados em ambiente seguro;
            </p>
            <p style={{ color: "#636363" }}>
              - Limitamos o acesso aos dados de nossos usuários, de modo que
              terceiros não autorizados não possam acessá-los;
            </p>
            <p style={{ color: "#636363" }}>
              - Mantemos registros de todos aqueles que têm, de alguma forma,
              contato com nossos dados.
            </p>
            <p style={{ color: "#636363" }}>
              Ainda que adote tudo o que está ao seu alcance para evitar
              incidentes de segurança, é possível que ocorra algum problema
              motivado exclusivamente por um terceiro - como em caso de ataques
              de hackers ou crackers ou, ainda, em caso de culpa exclusiva do
              usuário, que ocorre, por exemplo, quando ele mesmo transfere seus
              dados a terceiro. Assim, embora sejamos, em geral, responsáveis
              pelos dados pessoais que tratamos, nos eximimos de
              responsabilidade caso ocorra uma situação excepcional como essas,
              sobre as quais não temos nenhum tipo de controle. De qualquer
              forma, caso ocorra qualquer tipo de incidente de segurança que
              possa gerar risco ou dano relevante para qualquer de nossos
              usuários, comunicaremos os afetados e a Autoridade Nacional de
              Proteção de Dados acerca do ocorrido, em conformidade com o
              disposto na Lei Geral de Proteção de Dados.
            </p>
            <p style={{ color: "#636363" }}>
              <b> 7. Reclamação a uma autoridade de controle</b>
              <br />
              Sem prejuízo de qualquer outra via de recurso administrativo ou
              judicial, os titulares de dados pessoais que se sentirem, de
              qualquer forma, lesados, podem apresentar reclamação à Autoridade
              Nacional de Proteção de Dados.
            </p>
            <p style={{ color: "#636363" }}>
              <b>8. Alterações nesta política</b>
              <br />A presente versão desta Política de Privacidade foi
              atualizada pela última vez em: 27 de maio de 2022. Reservamo-nos o
              direito de modificar, a qualquer momento, as presentes normas,
              especialmente para adaptá-las às eventuais alterações feitas em
              nosso site, seja pela disponibilização de novas funcionalidades,
              seja pela supressão ou modificação daquelas já existentes. Sempre
              que houver uma modificação, nossos usuários serão notificados
              acerca da mudança.
            </p>
            <p style={{ color: "#636363" }}>
              <b>9. Como entrar em contato conosco</b>
              <br />
              Para esclarecer quaisquer dúvidas sobre esta Política de
              Privacidade ou sobre os dados pessoais que tratamos, entre em
              contato com nosso Encarregado de Proteção de Dados Pessoais, por
              algum dos canais mencionados abaixo:
            </p>
            <p style={{ color: "#636363" }}>
              E-mail: suporte@flexconsutla.com.br
              <br />
              Telefone: +55 65 9624-7057
            </p>
            <p style={{ color: "#636363" }}>
              Endereço: Av. Aclimação n° 243, Sala E, Bairro Bosque da Saúde, Cuiabá - MT, Cep 78.050-040
            </p>
          </Col>
        </Row>
        <FooterPartial />
      </Container>
      {/* <CookieComponent /> */}

    </>
  );
};

export default PoliticaPivacidade;
