import React from "react";
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import FooterPartial from "../../Home/Partials/Footer";
import NavbarPartial from "../../Home/Partials/Navbar";
// import CookieComponent from "../../Shared/Cookie";
// import BtnWhatsapp from "../../../Components/Button";
const PoliticaQualidade = () => {
  return (
    <>
      <NavbarPartial />
      {/* <BtnWhatsapp /> */}
      <Container fluid>
        <Row className="m-0 p-0">
          <Col
            style={{
              marginTop: "150px",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom:'auto',
              height: '100vh'

            }}
            md={6}
          >
            <h1 style={{ color: "#636363" }}>Política de qualidade</h1>

            <p style={{ color: "#636363" }}>
            “Qualidade na prestação de nossos serviços e segurança das informações a nós confiadas são a base de nosso negócio e responsabilidade de todos na organização”.

            </p>
            <p style={{ color: "#636363" }}>
            Nosso compromisso é prestar serviços de qualidade em todas as atividades de nossa responsabilidade. Sempre buscando o sigilo e a confidencialidade das informações trazidas por nossos clientes, e visando o crescimento da empresa com foco na sustentabilidade. Nosso maior desafio: manter a confiança, o grau de satisfação e superar as expectativas de nossos clientes a cada serviço prestado.

            </p>
         
          </Col>
        </Row>
        <FooterPartial />
      </Container>
      {/* <CookieComponent /> */}

    </>
  );
};

export default PoliticaQualidade;
