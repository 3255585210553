import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import ModalAdquiraFlex from "../../Modals/ModalAdquiraFlex"

export default () => {
      const [showModal, setShowModal] = useState(false);

      return <>
            {/* COMPONENTE INICIAL */}
            <ModalAdquiraFlex show={showModal} setShow={() => setShowModal(false)} />
            <Row id="part-inicio" className="d-flex justify-content-end align-itens-center justify-content-md-center">
                  <Col id={"inside-part-inicio"} md={4} className="col-12 col-md-6 ms-md-5 text-white mb-5 text-inicio">
                        <h2 className="fw700 mt-md-5 mt-sm-0 mt-lg-0 text-md-right">Inteligência artificial e tecnologia avançada para agilizar o transporte em todo território brasileiro.</h2>
                        <Button className="px-5 buttonPurple mt-4 h-widget-trigger">Saiba mais</Button>

                  </Col>
            </Row>

            {/* BANNER */}
            <Row id="part-banner" className="d-flex flex-column justify-content-center">
                  <Col id={'inside-banner'} md={3} className="col-12 col-sm-12 ms-auto me-auto me-md-5 text-white">
                        <h2 className="fw700 opacity-100">Conectamos quem conecta o Brasil de ponta a ponta.</h2>
                  </Col>
            </Row>
      </>
}