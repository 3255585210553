export function cpfMask(v) {
  if (v !== null) {
    v.replace(/\D/g, "");
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  }

  return v;
}

export function phoneMask(a) {
  let v = a.toString()
  if(v.length >= 11){
    v = v.replace(/(\d{2})(\d)/, "($1) $2");
    v = v.replace(/(\d{5})(\d{4})(\d)/, "$1-$2");

    v = v.replace(/^(\d\d)(\d)/g, "($1) $2");
    v = v.replace(/(\d{5})(\d)/, "$1-$2");
  }else{
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d\d)(\d)/g, "($1) $2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
  }

  
 /* v = v.replace(/^\d{12,13}$/,"")
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d\d)(\d)/g, "($1) $2");
  v = v.replace(/(\d{5})(\d)/, "$1-$2");*/

  return v;
}

export function validateEmail(inputText) {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if(inputText.match(mailformat)){
    return true
  }else{
    return false
  }
}

export function onlyNumbers(v) {
  v.replace(/\D/g, '');  
  v = v.replace(/\D/g, "")                
  return v;
}

