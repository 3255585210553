import React, { useState } from "react";
import {
  Row,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Image,
  Button,
} from "react-bootstrap";
import "./style.css";
import Logo from "../../../assets/logo.svg";
import pdfAvisoPrivacidade from '../../../assets/Pdf/Aviso_de_privacidade_Flex_Consulta_V1.2.pdf'
import pdfPoliticaDireitoTitulares from '../../../assets/Pdf/Politica_de_direito_dos_titulares_Flex_V1.2.pdf'
import pdfTermosUsoFlex from '../../../assets/Pdf/Termos_de_Uso_flex_consulta_V1.2.pdf'

//Modals
import ModalAdquiraFlex from "../../Modals/ModalAdquiraFlex";
import { Link } from "react-router-dom";
export default () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ModalAdquiraFlex show={showModal} setShow={() => setShowModal(false)} />

      <Navbar expand="lg" fixed="top" className="ps-5">
        <Container fluid>
          <Navbar.Brand href="./#part-inicio">
            <Image src={Logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-5 me-auto text-white">
              <Nav.Link className="mx-2" href="./#part-inicio">
                Início
              </Nav.Link>
              <NavDropdown
                className="mx-2"
                title="Institucional"
                id="basic-nav-dropdown"
              >


                 

                <NavDropdown.Item target="._blank" href={pdfAvisoPrivacidade}>
                  Aviso de privacidade
                </NavDropdown.Item>
                <NavDropdown.Item target="_blank" href={pdfPoliticaDireitoTitulares}>
                  Política de Direitos dos Titulares
                </NavDropdown.Item>
                <NavDropdown.Item target="_blank" href={pdfTermosUsoFlex}>
                Termos de uso
                </NavDropdown.Item>
                <NavDropdown.Item href="./#part-qsomos">
                  Sobre nós
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                className="mx-2"
                title="Soluções"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="./HistoricoViagensPage">
                  Histórico de Viagem
                </NavDropdown.Item>
                <NavDropdown.Item href="./BiometriaFacial">
                  Biometria Facial
                </NavDropdown.Item>
                <NavDropdown.Item href="./ValidacaoBiometria">
                  Validação de CNH
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className="mx-2" href="/#part-contact">
                Fale conosco
              </Nav.Link>
              <Nav.Link className="mx-2 d-inline-block d-lg-none" href="https://app.flexconsulta.com.br/">
                  Já sou membro
              </Nav.Link>
              <Nav.Link className="mx-2 d-inline-block d-lg-none h-widget-trigger">
                  Adquira a Flex
              </Nav.Link>
              
            </Nav>
            <a className="d-none d-lg-inline-block" href={"https://app.flexconsulta.com.br/"}>
              <Button className="buttonTransparent px-4">Já sou membro</Button>
            </a>
            <Button
              className="ms-3 px-4 buttonPurple d-none d-lg-inline-block h-widget-trigger">
              Adquira a Flex
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
