import React from "react";
import { Row, Col, Button, Image, Container } from "react-bootstrap";

import Logo from "../../../assets/logo.svg";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail, MdLocationPin, MdPhone } from "react-icons/md";
import {
  AiFillInstagram,
  AiFillLinkedin,
  AiFillFacebook,
} from "react-icons/ai";
import { RiWhatsappFill } from "react-icons/ri";
import LogoApp from "../../../assets/google-play-badge.png";
import pdfAvisoPrivacidade from '../../../assets/Pdf/Aviso_de_privacidade_Flex_Consulta_V1.2.pdf'
import pdfPoliticaDireitoTitulares from '../../../assets/Pdf/Politica_de_direito_dos_titulares_Flex_V1.2.pdf'
import pdfTermosUsoFlex from '../../../assets/Pdf/Termos_de_Uso_flex_consulta_V1.2.pdf'


export default () => {
  return (
    <>
      {/* COMPONENTE INICIAL */}
      <Row className="m-0 p-0" style={{ bottom: 0, position: "relative" }}>
        <Col className="m-0 p-0">
          <Row id="part-footer" className="font16 p-3 p-md-5">
            <Col className="col-1"></Col>
            <Col className="mt-5 mt-md-0 p-0" md={4}>
              <Row className="d-flex m-0 p-0">
                <Col className="col-1" style={{ marginRight: "10px" }}>
                  <MdPhone size={25} className="me-3" />
                </Col>
                <Col className="col-10">
                  <p>
                    <span>
                      Telefone: +55 (65) 4042-9496
                      <br />
                      Horário de atendimento: segunda a sexta: 8h às 12h e 14h
                      às 18h sábado: 8h às 12h (exceto feriados)
                    </span>
                  </p>
                </Col>
              </Row>
              <Row className="d-flex m-0 p-0">
                <Col className="col-1" style={{ marginRight: "10px" }}>
                  <MdEmail size={25} className="me-3" />
                </Col>
                <Col className="col-10">
                  <p>
                    <span>E-mail: suporte@flexconsulta.com.br</span>
                  </p>
                </Col>
              </Row>
              <Row className="d-flex m-0 p-0">
                <Col className={"col-1"} style={{ marginRight: "10px" }}>
                  <MdLocationPin size={25} />
                </Col>
                <Col className="col-10">
                  <p>
                    <span>
                      Av. Aclimação n° 243, Sala E, Bairro Bosque da Saúde, Cuiabá - MT, Cep 78.050-040
                    </span>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col className="mt-5 mt-md-0 d-none d-md-block">
              <Row>
                <Col className="col-12">
                  <a target="_blank" href={pdfAvisoPrivacidade}>Aviso de privacidade</a>{" "}
                  <br />
                </Col>
                <Col className="col-12">
                  <a target="_blank" href={pdfPoliticaDireitoTitulares}>Política de Direitos dos Titulares</a>{" "}
                </Col>
                <Col className="col-12">
                  <a target="_blank" href={pdfTermosUsoFlex}>Termos de uso</a>{" "}
                </Col>
                <Col className="col-12" >
                  <div onClick={() => { window.location.href = 'https://play.google.com/store/apps/details?id=com.appflexconsulta&hl=pt_BR&gl=US' }} style={{ marginLeft: '-1rem', cursor: 'pointer' }}>
                    <img style={{ width: '11rem' }} src={LogoApp} />
                  </div>{" "}
                </Col>
              </Row>
            </Col>
            <Col className="mt-5 mt-md-0 d-flex flex-column align-items-md-center">
              <div className="mb-4">
                <a
                  href="https://www.instagram.com/flexconsulta/"
                  target="_blank"
                >
                  <AiFillInstagram size={30} />
                </a>
                <a href="https://wa.me/556599860618/" target="_blank">
                  <RiWhatsappFill size={30} />
                </a>
                <a
                  href="https://www.linkedin.com/company/flexconsulta/"
                  target="_blank"
                >
                  <AiFillLinkedin size={30} />
                </a>
                <a href="https://www.facebook.com/flexconsulta" target="_blank">
                  <AiFillFacebook size={30} />
                </a>
              </div>
              <Row className=" d-flex d-md-none my-3 my-md-0">
                <Col className="col-12" style={{ marginTop: '12px' }}>
                  <a target="_blank" href={pdfAvisoPrivacidade}>Aviso de privacidade</a>{" "}
                </Col>
                <Col className="col-12">
                  <a target="_blank" href={pdfPoliticaDireitoTitulares}>Política de Direitos dos Titulares</a>{" "}
                </Col>
                <Col className="col-12">
                  <a target="_blank" href={pdfTermosUsoFlex}>Termos de uso</a>{" "}
                </Col>
                <Col className="col-12" >
                  <div onClick={() => { window.location.href = 'https://play.google.com/store/apps/details?id=com.appflexconsulta&hl=pt_BR&gl=US' }} style={{ marginLeft: '-1rem', cursor: 'pointer' }}>
                    <img style={{ width: '11rem' }} src={LogoApp} />
                  </div>{" "}
                </Col>
              </Row>
              <span className="mb-4 d-md-flex d-none ">
                Flex
                <span style={{ fontSize: "12px", verticalAlign: "top" }}>
                  {String.fromCharCode(174)}{" "}
                </span>
                2022. Todos os direitos reservados.
              </span>
              <span style={{ whiteSpace: 'nowrap', marginBottom: '3px' }} >
                CNPJ 42.520.419/0001-14
              </span>
              <span className="mb-4 d-md-none d-flex ">
                Flex
                <span style={{ fontSize: "12px", verticalAlign: "top" }}>
                  {String.fromCharCode(174)}{" "}
                </span>
                2022. Todos os direitos reservados.
              </span>
            </Col>
          </Row>
          <Row></Row>

          {/* BANNER */}
          <Row id="part-sub-footer">
            <Col className="p-3"></Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
